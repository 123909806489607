import DataTable from 'datatables.net-dt';

$(document).ready(function() {
  var dataTable = new DataTable('#manuals-table', {
    ajax: {
      url: '/?wc-ajax=product_manuals',
      dataSrc: ''
    },
    deferRender: true,
    paging: true,
    pageLength: 20,
    columnDefs: [
      { targets: [0], searchable: true },
    ],
    language: {
      emptyTable: "Loading...",
      zeroRecords: "<p>Didn't find what you were looking for? <a href='/contact-us'>Contact us</a> and we will provide the manual you are looking for.</p>"
    },
    pagingType: 'simple_numbers',
    dom: '<"top"f>rt<"table-info"ip>',
  });

  function setSearchPlaceholder() {
    const screenWidth = $(window).width();
    const placeholderText = screenWidth < 600 ? 'Search...' : 'Search by product name, model/part number, etc.';
    const searchInput = $('#manuals-table_filter input');

    searchInput.attr('placeholder', placeholderText);
    dataTable.search('').draw();
  }

  setSearchPlaceholder();
  $(window).on('resize', setSearchPlaceholder);
});
